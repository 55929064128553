import cloneDeep from 'lodash/cloneDeep';
import { TreeInfoEnum, TreeInfo } from '../typeInfo';
import { getDefaultTreeSettings, isDarkMode } from '../../components/Utils'

let newState: TreeInfo;

const defaultInfo = {
    id: '0',
    treeName: "",
    treeDesc: "",
    treeData: {},
    treeEdit: false,
    treeSettings: getDefaultTreeSettings(isDarkMode()),
    isPrivate: false,
    isOwned: true
}

const currentTreeInfo = (state = defaultInfo, action: { type: TreeInfoEnum, payload: any }) => {
    switch (action.type) {
        case TreeInfoEnum.TREE_NAME:
            newState = cloneDeep(state);
            newState.treeName = action.payload.treeName;
            return newState;
        case TreeInfoEnum.TREE_DESC:
            newState = cloneDeep(state);
            newState.treeDesc = action.payload.treeDesc;
            return newState;
        case TreeInfoEnum.TREE_DATA:
            newState = cloneDeep(state);
            newState.treeData = cloneDeep(action.payload.treeData);
            return newState;
        case TreeInfoEnum.TREE_ID:
            newState = cloneDeep(state);
            newState.id = action.payload.id;
            return newState;
        case TreeInfoEnum.TREE_PRIVATE:
            newState = cloneDeep(state);
            newState.isPrivate = action.payload.isPrivate;
            return newState;
        case TreeInfoEnum.TREE_EDIT:
            newState = cloneDeep(state);
            newState.treeEdit = action.payload.edit;
            return newState;
        case TreeInfoEnum.TREE_OWNED:
            newState = cloneDeep(state);
            newState.isOwned = action.payload.owned;
            return newState;
        case TreeInfoEnum.TREE_SETTINGS:
            newState = cloneDeep(state);
            if(action.payload.treeSettings)
                newState.treeSettings = cloneDeep(action.payload.treeSettings);
            return newState;
        default:
            return state;
    }
}

export default currentTreeInfo;