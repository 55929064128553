
import { combineReducers } from 'redux';
import appInfo from './appInfo';
import treeListInfo from './treeListInfo'
import contactListInfo from './contactListInfo'
import currentTreeInfo from './currentTreeInfo'
import profileInfo from './profileInfo'
import serviceWorker from './serviceWorker';

const rootReducer = combineReducers({
    appInfo: appInfo,
    currentTreeInfo: currentTreeInfo,
    profileInfo: profileInfo,
    treeListInfo: treeListInfo,
    contactListInfo: contactListInfo,
    serviceWorker: serviceWorker
})

//export default rootReducer;
const defaultReducer = (state : any, action : any) => rootReducer(action.type === 'USER_LOGOUT' ? undefined : state, action);
export default defaultReducer;