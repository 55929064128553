
export enum Views {
    EMPTY_VIEW = 'EMPTY_VIEW',
    INDEX_PAGE_VIEW = 'INDEX_PAGE_VIEW',
    PROFILE_PAGE_VIEW = 'PROFILE_PAGE_VIEW',
    PROFILEEDIT_PAGE_VIEW = 'PROFILEEDIT_PAGE_VIEW',
    SETTINGS_PAGE_VIEW = 'SETTINGS_PAGE_VIEW',
    CONTACTS_PAGE_VIEW = 'CONTACTS_PAGE_VIEW',
    TREEVIEW_PAGE_VIEW = 'TREEVIEW_PAGE_VIEW',
    TREEEDIT_PAGE_VIEW = 'TREEEDIT_PAGE_VIEW',
    NODEEDIT_PAGE_VIEW = 'NODEEDIT_PAGE_VIEW',
    TREELIST_PAGE_VIEW = 'TREELIST_PAGE_VIEW',
    LOGIN_PAGE_VIEW = 'LOGIN_PAGE_VIEW',
    SIGNUP_PAGE_VIEW = 'SIGNUP_PAGE_VIEW',
    RESETPASSWORD_PAGE_VIEW = 'RESETPASSWORD_PAGE_VIEW',
    LOAD_DATA_VIEW='LOAD_DATA_VIEW',
    RESET_PAGE_VIEW='RESET_PAGE_VIEW'
}

export enum AppInfoEnum {
    ACTIVE_VIEW = 'ACTIVE_VIEW',
    LAST_ACTIVE_VIEW = 'LAST_ACTIVE_VIEW',
    DARK_THEME = 'DARK_THEME',
    DATA_LOADED = 'DATA_LOADED'
}

export interface AppInfo {
    activeView : Views,
    lastActiveView : Views,
    darkTheme : boolean,
    dataLoaded : boolean
}

export interface TreeSettings {
    fontFamily: string,
    fontColor: string,
    fontSize: number,
    linkColor: string,
    linkOpacity: number,
    linkWidth: number,
    canvasColor: string,
    texture: string,
}

export interface TreeInfo {
    id: string,
    treeName: string,
    treeDesc: string,
    treeData: any,
    treeEdit: boolean,
    treeSettings: TreeSettings,
    isPrivate: boolean,
    isOwned: boolean
}

export interface ContactInfo {
    id: string,
    name: string,
    mother: string,
    father: string,
    spouse: string,
    trees: number,
    gender: string,
    pic: string,
    email: string
}

export enum TreeInfoEnum {
    TREE_NAME='TREE_NAME',
    TREE_DESC='TREE_DESC',
    TREE_DATA='TREE_DATA',
    TREE_ID='TREE_ID',
    TREE_EDIT='TREE_EDIT',
    TREE_SETTINGS='TREE_SETTINGS',
    TREE_PRIVATE='TREE_PRIVATE',
    TREE_OWNED='TREE_OWNED'
}

export enum TreeListInfoEnum {
    ADD_TREE_TO_LIST= 'ADD_TREE_TO_LIST',
    EDIT_TREE_IN_LIST='EDIT_TREE_IN_LIST',
    REMOVE_TREE_FROM_LIST='REMOVE_TREE_FROM_LIST'
}

export enum ContactListInfoEnum {
    ADD_CONTACT_TO_LIST= 'ADD_CONTACT_TO_LIST',
    REMOVE_CONTACT_FROM_LIST='REMOVE_CONATCT_FROM_LIST',
}

export enum ProfileInfoEnum {
    USER_ACTIVE='USER_ACTIVE',
    PROFILE_IMAGE='PROFILE_IMAGE',
    USER_NAME='USER_NAME',
    USER_FATHER='USER_FATHER',
    USER_MOTHER='USER_MOTHER',
    USER_SPOUSE='USER_SPOUSE',
    USER_GENDER='USER_GENDER',
    ABOUT_ME='ABOUT_ME'
}

export interface ProfileInfo {
    isLoggedIn: boolean,
    profileImage: Blob|undefined,
    name: string,
    gender: string,
    fatherName: string,
    motherName: string,
    spouseName: string,
    aboutMe: string
}

export const SW_INIT = 'SW_INIT';
export const SW_UPDATE = 'SW_UPDATE';

