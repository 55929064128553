import cloneDeep from 'lodash/cloneDeep';
import { isDarkMode } from '../../components/Utils';
import { Views, AppInfoEnum, AppInfo } from '../typeInfo';

let newState: AppInfo;
const appInfo = (state = { activeView: Views.EMPTY_VIEW, lastActiveView: Views.EMPTY_VIEW, darkTheme: isDarkMode(), dataLoaded: false}, action: { type: AppInfoEnum, payload: { view: Views, darkTheme: boolean, dataLoaded: boolean } }) => {
    switch (action.type) {
        case AppInfoEnum.ACTIVE_VIEW:
            newState = cloneDeep(state);
            newState.activeView = action.payload.view;
            return newState;
        case AppInfoEnum.LAST_ACTIVE_VIEW:
            newState = cloneDeep(state);
            newState.lastActiveView = action.payload.view;
            return newState;
        case AppInfoEnum.DARK_THEME:
            newState = cloneDeep(state);
            newState.darkTheme = action.payload.darkTheme;
            return newState;
        case AppInfoEnum.DATA_LOADED:
            newState = cloneDeep(state);
            newState.dataLoaded = action.payload.dataLoaded;
            return newState;
        default:
            return state;
    }
}

export default appInfo