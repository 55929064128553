import cloneDeep from 'lodash/cloneDeep';
import { ContactListInfoEnum, ContactInfo } from '../typeInfo';

let newState: Array<ContactInfo>;
const defaultInfo: Array<ContactInfo> = [];
let index = -1;
const currentTreeInfo = (state = defaultInfo, action: { type: ContactListInfoEnum, payload: ContactInfo }) => {
    switch (action.type) {
        case ContactListInfoEnum.ADD_CONTACT_TO_LIST:
            newState = cloneDeep(state);
            newState.push(cloneDeep(action.payload));
            return newState;
        case ContactListInfoEnum.REMOVE_CONTACT_FROM_LIST:
            newState = cloneDeep(state);
            index = newState.findIndex((item) => item.id === action.payload.id);
            newState.splice(index,1);
            return newState;
        default:
            return state;
    }
}

export default currentTreeInfo;