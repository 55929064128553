import firebase from "firebase/app";
import "firebase/auth";
import 'firebase/storage';
import 'firebase/firestore';
import {firebaseConfig} from './configs/firebaseConfig';

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);

//firebase.analytics();
export const db = firebase.firestore();
db.enablePersistence()
    .catch(function(err: { code: string; }) {
        if (err.code === 'failed-precondition') {
            console.log("Multiple tabs open, persistence can only be enabled in one tab at a a time.");
        } else if (err.code === 'unimplemented') {
            console.log("The current browser does not support all of the features required to enable persistence");
        }
    });

//default locations
export const getTreeCollectionRef = (uid: string)=>{
    return db.collection('treeData').doc(uid).collection('trees');
}

export const getBookmarkedTreeCollectionRef = (uid: string)=>{
    return db.collection('treeData').doc(uid).collection('bookmarkedTrees');
}

export const getContactsCollectionRef = (uid: string) => {
    return db.collection('contactsData').doc(uid).collection('contacts');
}

export const getProfileRef = (uid: string)=>{
    return db.collection('profileData').doc(uid);
}

export const getEmailUidMapRef = (email: string)=>{
    return db.collection('emailData').doc(email);
}


export const provider = new firebase.auth.GoogleAuthProvider();

export type User = firebase.User;
export const storage = firebase.storage();
export const auth = app.auth();
export default app;