import cloneDeep from 'lodash/cloneDeep';
import { ProfileInfoEnum, ProfileInfo } from '../typeInfo';

let newState: ProfileInfo;
const defaultInfo = {
    isLoggedIn: false,
    profileImage: undefined,
    name: "",
    gender: "male",
    fatherName: "",
    motherName: "",
    spouseName: "",
    aboutMe: ""
}

const profileInfo = (state = defaultInfo, action: { type: ProfileInfoEnum, payload: any }) => {
    switch (action.type) {
        case ProfileInfoEnum.USER_ACTIVE:
            newState = cloneDeep(state);
            newState.isLoggedIn = action.payload.isLoggedIn;
            return newState;
        case ProfileInfoEnum.PROFILE_IMAGE:
            newState = cloneDeep(state);
            newState.profileImage = action.payload.profileImage;
            return newState;
        case ProfileInfoEnum.USER_NAME:
            newState = cloneDeep(state);
            newState.name = action.payload.name;
            return newState;
        case ProfileInfoEnum.USER_FATHER:
            newState = cloneDeep(state);
            newState.fatherName = action.payload.name;
            return newState;
        case ProfileInfoEnum.USER_MOTHER:
            newState = cloneDeep(state);
            newState.motherName = action.payload.name;
            return newState;
        case ProfileInfoEnum.USER_SPOUSE:
            newState = cloneDeep(state);
            newState.spouseName = action.payload.name;
            return newState;
        case ProfileInfoEnum.USER_GENDER:
            newState = cloneDeep(state);
            newState.gender = action.payload.gender;
            return newState;
        default:
            return state;
    }
}

export default profileInfo;