import React, { useContext, useEffect, useState } from 'react'
import { auth, User, provider } from '../firebase';

const AuthContext = React.createContext({} as any);

export const useAuth = () => {
    return useContext(AuthContext);
}

export const AuthProvider = ({ children }: any) => {
    const [currentUser, setCurrentUser] = useState({} as User);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user: User | null) => {
            setCurrentUser(user as User);
            setLoading(false);
        })
        return unsubscribe;
    }, []);

    const signUp = (email: string, password: string) => {
        return auth.createUserWithEmailAndPassword(email, password);
    }

    const logIn = (email: string, password: string) => {
        return auth.signInWithEmailAndPassword(email, password);
    }

    const logInUsingGoogle = () => {
        return auth.signInWithRedirect(provider);
    }

    const logOut = () => {
        return auth.signOut();
    }

    const resetPassword = (email: string) => {
        return auth.sendPasswordResetEmail(email);
    }

    const updateEmail = (email: string) => {
        return currentUser.updateEmail(email);
    }

    const updatePassword = (password: string) => {
        return currentUser.updatePassword(password);
    }

    const value = {
        currentUser,
        signUp,
        logIn,
        logOut,
        resetPassword,
        updateEmail,
        updatePassword,
        logInUsingGoogle
    }

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}
