import { SW_INIT, SW_UPDATE } from '../typeInfo';

const serviceWorker = (state = {serviceWorkerInitialized: false, serviceWorkerUpdated: false, serviceWorkerRegistration: null}, action: any) => {
  switch (action.type) {
    case SW_INIT:
      return {
        ...state,
        serviceWorkerInitialized: !state.serviceWorkerInitialized,
      };
    case SW_UPDATE:
      return {
        ...state,
        serviceWorkerUpdated: !state.serviceWorkerUpdated,
        serviceWorkerRegistration: action.payload,
      };
    default:
      return state;
  }
}
export default serviceWorker;