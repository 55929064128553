import cloneDeep from 'lodash/cloneDeep';
import { TreeListInfoEnum, TreeInfo } from '../typeInfo';

let newState: Array<TreeInfo>;
const defaultInfo: Array<TreeInfo> = [];
let index = -1;
const currentTreeInfo = (state = defaultInfo, action: { type: TreeListInfoEnum, payload: TreeInfo }) => {
    switch (action.type) {
        case TreeListInfoEnum.ADD_TREE_TO_LIST:
            newState = cloneDeep(state);
            newState.push(cloneDeep(action.payload));
            return newState;
        case TreeListInfoEnum.EDIT_TREE_IN_LIST:
            newState = cloneDeep(state);
            index = newState.findIndex((item) => item.id === action.payload.id);
            newState[index] = cloneDeep(action.payload);     
            return newState;
        case TreeListInfoEnum.REMOVE_TREE_FROM_LIST:
            newState = cloneDeep(state);
            index = newState.findIndex((item) => item.id === action.payload.id);
            newState.splice(index,1);
            return newState;
        default:
            return state;
    }
}

export default currentTreeInfo;