
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { useLottie } from 'lottie-react';

export const getDefaultTreeSettings = (darkTheme: boolean) => {
    return {
        fontFamily: "Roboto",
        fontColor: (darkTheme ? 'white': 'black'),
        fontSize: 14,
        linkOpacity: 0.8,
        linkColor: `#2593b8`,
        linkWidth: 0.8,
        canvasColor: getPaperBgColor(darkTheme),
        texture: 'none',
        isPrivate: false
    }
}

export const getBackgroundUrl = (textureName: string) => {
    if(textureName === 'none')
        return textureName;
    if(!textureName)
        return 'url(https://www.transparenttextures.com/patterns/arabesque.png)';
    return 'url(https://www.transparenttextures.com/patterns/'+textureName+'.png)';
}

export const getBgColorWithOpacity = (theme: any) => {
    return theme.palette.background.paper+"E6";
}

export function iOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

export  const getTreeIcon = (iconColor: any) => {
    return <svg enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill={iconColor}>
            <g className="nav" ><rect fill="none" height="24" width="24"/></g>
            <g className="nav" ><polygon points="17,12 19,12 12,2 5.05,12 7,12 3.1,18 10.02,18 10.02,22 13.98,22 13.98,18 21,18"/></g>
            </svg>
}

export const scrollToElement = (targetElement: HTMLElement | undefined) => {
    if (targetElement) {
        targetElement.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "start"
        });
    }
};

export const getPrimaryMainColor = (prefersDark: boolean) => {
    return prefersDark? '#ffc400' : '#FF6D00'
}

export const getPaperBgColor = (prefersDark: boolean) => {
    return prefersDark ? '#424242': '#e8e8e8'
}

export const isDarkMode = () => {
    return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
}


export function Alert(props: AlertProps) {
    return <MuiAlert elevation={2} variant="filled" {...props} />;
}

export const GetLottieAnim = (props: any) => {
    const options = {
        animationData: props.data,
        loop: true,
        autoplay: true,
      };
    const { View } = useLottie(options);
    return View;
}