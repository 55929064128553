import React, { useEffect } from 'react';
import { AuthProvider } from './context/AuthContext';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import loadable from '@loadable/component';
import type MuiAlert from '@material-ui/lab/Alert';
import { getPaperBgColor, getPrimaryMainColor } from './components/Utils';


const Foundation = loadable(() => import('./components/Foundation'), {resolveComponent: (mod) => mod.Foundation})
const SharedTreeView = loadable(() => import('./components/SharedTreeView'), {resolveComponent: (mod) => mod.SharedTreeView})


declare module '@material-ui/core/styles/overrides' {
  export interface ComponentNameToClassKey {
    MuiAlert: typeof MuiAlert;
  }
}

function App() {
  const appInfo = useSelector((state: any) => state.appInfo);
  const prefersDarkMode = appInfo.darkTheme; 
  
  const theme = React.useMemo(
    () =>
      createMuiTheme({
        overrides: {
          MuiPaper : {
            root : {
              /*backgroundImage: prefersDarkMode ? `linear-gradient(147deg, #000000 0%, #434343 64%)` : ``,*/
            }
          },
          MuiGrid:{
            root : {/*
              backgroundImage: prefersDarkMode ?
              `radial-gradient(circle at center center, transparent,rgb(33,33,33)),repeating-linear-gradient(135deg, rgb(33,33,33) 0px, rgb(33,33,33) 2px,transparent 2px, transparent 10px,rgb(33,33,33) 10px, rgb(33,33,33) 11px,transparent 11px, transparent 21px),repeating-linear-gradient(45deg, rgb(47,47,47) 0px, rgb(47,47,47) 4px,transparent 4px, transparent 8px),linear-gradient(90deg, rgb(33,33,33),rgb(33,33,33))`
              : `repeating-linear-gradient(135deg, hsla(35,0%,71%,0.09) 0px, hsla(35,0%,71%,0.09) 1px,transparent 1px, transparent 11px),repeating-linear-gradient(45deg, hsla(35,0%,71%,0.09) 0px, hsla(35,0%,71%,0.09) 1px,transparent 1px, transparent 11px),linear-gradient(90deg, hsl(183,0%,100%),hsl(183,0%,100%))`
            */
           }
          },
          MuiAlert: {
            root : {
              //backgroundImage: 'none'
            }
          },
        },
        palette: {
          type: prefersDarkMode ? 'dark' : 'light',
          primary: {
            main: getPrimaryMainColor(prefersDarkMode)
          },
          background: {
            paper: getPaperBgColor(prefersDarkMode)
          }
        },
      }),
    [prefersDarkMode],
  );


  useEffect(() => {
    Foundation.preload();
    SharedTreeView.preload();
    document.body.style.backgroundColor = theme.palette.background.paper;
  }, [theme])
  
  
  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Switch>
            <Route path='/' exact component={Foundation} />
            <Route path="/:param" children={<SharedTreeView/>} />
          </Switch>
        </BrowserRouter>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;